
import React, { Component } from 'reactn'
import { Link } from 'react-router-dom';
import './MenuCmp.scss';
import logo from '../../../assets/img/logo_128x128.webp';

class MenuCmp extends Component {
    state = {}

    render() {
        return (

            <div className='MenuCmp'>

                <div className='outer-box'>

                    <Link to='/' className='home_link'>
                        <div className='logo-holder'>
                            <div className='logo'  >
                                <img src={logo} alt="logo" />
                            </div>

                            <div className='name'>Smart Fox Innovation</div>

                        </div>
                    </Link>

                    <div className="nav-link-holder">
                        {navItem('News', '/#anchor-timeline')}
                        {navItem('Products', '/#anchor-products')}
                        {navItem('Values', '/#anchor-values')}
                        {navItem('Contact', '/#anchor-contact')}
                        {navItem('About', '/#anchor-about')}
                    </div>
                </div>

            </div>

        );
    }
}


function navItem($text, $link) {


    if ($link.includes("#")) {
        return <a href={$link} className="nav-links">
            {$text}
        </a>
    } else {
        return <Link to={$link} className="nav-links" > {$text} </Link>
    }

}






export default MenuCmp;