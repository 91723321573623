import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './DPRGPage.scss'
import DPRGRequesterCMP from '../../elements/dprg_requester/DPRGRequesterCMP'
import FeedbackCmp from "../../elements/feedback/FeedbackCmp";
import MovieCMP from "../../elements/movie/MovieCMP";


class DPRGPage extends Component {
    state = {

        dprg_sample: [
            { file: 'RCv13f4-20230728_N1690673665402_L10_D16_D.txt', name: 'SampleA001', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665479_L10_D16_D.txt', name: 'SampleA002', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665498_L10_D16_D.txt', name: 'SampleA003', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665515_L10_D16_D.txt', name: 'SampleA004', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665529_L10_D16_D.txt', name: 'SampleA005', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665545_L10_D16_D.txt', name: 'SampleA006', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665557_L10_D16_D.txt', name: 'SampleA007', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665568_L10_D16_D.txt', name: 'SampleA008', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665580_L10_D16_D.txt', name: 'SampleA009', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665589_L10_D16_D.txt', name: 'SampleA010', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665600_L10_D16_D.txt', name: 'SampleA011', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665611_L10_D16_D.txt', name: 'SampleA012', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665621_L10_D16_D.txt', name: 'SampleA013', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665634_L10_D16_D.txt', name: 'SampleA014', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665646_L10_D16_D.txt', name: 'SampleA015', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665657_L10_D16_D.txt', name: 'SampleA016', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665668_L10_D16_D.txt', name: 'SampleA017', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665678_L10_D16_D.txt', name: 'SampleA018', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665689_L10_D16_D.txt', name: 'SampleA019', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665699_L10_D16_D.txt', name: 'SampleA020', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665709_L10_D16_D.txt', name: 'SampleA021', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665719_L10_D16_D.txt', name: 'SampleA022', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665730_L10_D16_D.txt', name: 'SampleA023', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665739_L10_D16_D.txt', name: 'SampleA024', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665750_L10_D16_D.txt', name: 'SampleA025', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665761_L10_D16_D.txt', name: 'SampleA026', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665772_L10_D16_D.txt', name: 'SampleA027', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665782_L10_D16_D.txt', name: 'SampleA028', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665792_L10_D16_D.txt', name: 'SampleA029', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665803_L10_D16_D.txt', name: 'SampleA030', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665813_L10_D16_D.txt', name: 'SampleA031', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665826_L10_D16_D.txt', name: 'SampleA032', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665839_L10_D16_D.txt', name: 'SampleA033', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665851_L10_D16_D.txt', name: 'SampleA034', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665862_L10_D16_D.txt', name: 'SampleA035', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665871_L10_D16_D.txt', name: 'SampleA036', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665880_L10_D16_D.txt', name: 'SampleA037', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665890_L10_D16_D.txt', name: 'SampleA038', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665900_L10_D16_D.txt', name: 'SampleA039', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665910_L10_D16_D.txt', name: 'SampleA040', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665921_L10_D16_D.txt', name: 'SampleA041', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665931_L10_D16_D.txt', name: 'SampleA042', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665942_L10_D16_D.txt', name: 'SampleA043', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665956_L10_D16_D.txt', name: 'SampleA044', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665967_L10_D16_D.txt', name: 'SampleA045', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665978_L10_D16_D.txt', name: 'SampleA046', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673665990_L10_D16_D.txt', name: 'SampleA047', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666000_L10_D16_D.txt', name: 'SampleA048', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666009_L10_D16_D.txt', name: 'SampleA049', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666018_L10_D16_D.txt', name: 'SampleA050', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666029_L10_D16_D.txt', name: 'SampleA051', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666038_L10_D16_D.txt', name: 'SampleA052', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666047_L10_D16_D.txt', name: 'SampleA053', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666056_L10_D16_D.txt', name: 'SampleA054', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666066_L10_D16_D.txt', name: 'SampleA055', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666076_L10_D16_D.txt', name: 'SampleA056', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666087_L10_D16_D.txt', name: 'SampleA057', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666097_L10_D16_D.txt', name: 'SampleA058', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666108_L10_D16_D.txt', name: 'SampleA059', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666117_L10_D16_D.txt', name: 'SampleA060', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666128_L10_D16_D.txt', name: 'SampleA061', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666137_L10_D16_D.txt', name: 'SampleA062', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666146_L10_D16_D.txt', name: 'SampleA063', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666158_L10_D16_D.txt', name: 'SampleA064', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666168_L10_D16_D.txt', name: 'SampleA065', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666183_L10_D16_D.txt', name: 'SampleA066', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666196_L10_D16_D.txt', name: 'SampleA067', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666207_L10_D16_D.txt', name: 'SampleA068', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666217_L10_D16_D.txt', name: 'SampleA069', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666228_L10_D16_D.txt', name: 'SampleA070', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666238_L10_D16_D.txt', name: 'SampleA071', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666250_L10_D16_D.txt', name: 'SampleA072', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666258_L10_D16_D.txt', name: 'SampleA073', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666268_L10_D16_D.txt', name: 'SampleA074', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666279_L10_D16_D.txt', name: 'SampleA075', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666289_L10_D16_D.txt', name: 'SampleA076', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666299_L10_D16_D.txt', name: 'SampleA077', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666309_L10_D16_D.txt', name: 'SampleA078', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666321_L10_D16_D.txt', name: 'SampleA079', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666332_L10_D16_D.txt', name: 'SampleA080', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666342_L10_D16_D.txt', name: 'SampleA081', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666352_L10_D16_D.txt', name: 'SampleA082', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666363_L10_D16_D.txt', name: 'SampleA083', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666373_L10_D16_D.txt', name: 'SampleA084', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666383_L10_D16_D.txt', name: 'SampleA085', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666393_L10_D16_D.txt', name: 'SampleA086', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666404_L10_D16_D.txt', name: 'SampleA087', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666413_L10_D16_D.txt', name: 'SampleA088', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666424_L10_D16_D.txt', name: 'SampleA089', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666435_L10_D16_D.txt', name: 'SampleA090', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666445_L10_D16_D.txt', name: 'SampleA091', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666453_L10_D16_D.txt', name: 'SampleA092', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666463_L10_D16_D.txt', name: 'SampleA093', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666472_L10_D16_D.txt', name: 'SampleA094', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666482_L10_D16_D.txt', name: 'SampleA095', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666493_L10_D16_D.txt', name: 'SampleA096', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666504_L10_D16_D.txt', name: 'SampleA097', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666517_L10_D16_D.txt', name: 'SampleA098', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666530_L10_D16_D.txt', name: 'SampleA099', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673666560_L10_D16_D.txt', name: 'SampleA100', digits: '16', size: '10' },
            { file: 'RCv13f4-20230728_N1690673703219_L100_D16_D.txt', name: 'SampleB01', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703305_L100_D16_D.txt', name: 'SampleB02', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703326_L100_D16_D.txt', name: 'SampleB03', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703343_L100_D16_D.txt', name: 'SampleB04', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703359_L100_D16_D.txt', name: 'SampleB05', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703376_L100_D16_D.txt', name: 'SampleB06', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703392_L100_D16_D.txt', name: 'SampleB07', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703406_L100_D16_D.txt', name: 'SampleB08', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703421_L100_D16_D.txt', name: 'SampleB09', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673703435_L100_D16_D.txt', name: 'SampleB10', digits: '16', size: '100' },
            { file: 'RCv13f4-20230728_N1690673710605_L1000_D16_D.txt', name: 'SampleC1', digits: '16', size: '1000' },
            { file: 'RCv13f4-20230728_N1690673715715_L10000_D16_D.txt', name: 'SampleC2', digits: '16', size: '10000' },
            { file: 'RCv13f4-20230728_N1690673720937_L100000_D16_D.txt', name: 'SampleC3', digits: '16', size: '100000' },
            { file: 'RCv13f4-20230728_N1690673725985_L1000000_D16_D.txt', name: 'SampleC4', digits: '16', size: '1000000' },
            { file: 'RCv13f4-20230728_N1690673738443_L10000000_D16_D.txt', name: 'SampleC5', digits: '16', size: '10000000' },

        ]

    }




    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        //let sample_list = this.state.dprg_sample.map(addExampleFile);


        return (
            <div className="DPRGPage">
                <div className="title">DPRG  <h5>Dynamic Pseudo Random Number Generator</h5></div>

                <div className="info-box">
                    <img src="/images/products/dprg_logo.png" alt="DPRG" className="img" />
                    <div className="description">
                        High-quality random number generator designed for cryptographic applications and other scenarios where randomness is critical. Our solution is a state-of-the-art solution designed to meet the most demanding requirements. Leveraging advanced algorithms, it delivers high-quality random numbers that pass stringent statistical randomness tests. This product is ideal for applications in cryptography, statistical sampling, computer simulations, and more. Experience unparalleled randomness, where quality meets unpredictability.
                    </div>
                </div>

                <MovieCMP videoName="dprg.mp4" ></MovieCMP>


                <div className="description">
                    <h1>We are proud to announce the release of DPRG v1.0 !</h1>
                    Our high-quality random number generator designed for cryptographic applications and other scenarios where strong randomness is essential.
                    <br />
                    <br />
                    DPRG v1.0 has undergone extensive testing and validation, and we believe it is a significant advancement in the field of random number generation.
                    <strong> Now, we need your help to make DPRG even better!</strong>   <br />
                    <br />
                    We invite the community to review DPRG v1.0 and provide valuable feedback. Your expertise and insights can play a crucial role in ensuring the generator's robustness, security, and usability.
                    <br /> <strong>Here's how you can contribute:</strong>
                    <br /><br />
                    <h4><strong>1. Test and Evaluate:</strong></h4>
                    We provide DPRG v1.0 generated sequences for test it in your preferred environments.
                    <br />
                    Evaluate its quality, check the statistical test results, and put it through rigorous scenarios.
                    Report Issues: If you encounter any bugs, inconsistencies, or unexpected behavior, please report them to us. Your feedback will help us identify and address any potential problems.

                    <h4><strong>2. Security Analysis:</strong></h4>
                    If you have expertise in cryptography or security, we invite you to perform a security analysis of DPRG v1.0. Identify any possible vulnerabilities or weaknesses that might have been missed during our testing.
                    Provide Performance Feedback: Let us know how DPRG v1.0 performs in real-world use cases.

                    <h4><strong>3. Share your experience</strong></h4>
                    Provide your point of view and suggestions for optimization.
                    Contribute to Documentation: If you find areas where the documentation can be improved, or if you'd like to add examples and use cases, your contributions are most welcome.
                    <h4>4. Collaborate and Share Ideas:</h4>
                    Have ideas to enhance DPRG? <br />
                    Your input will help to shape the future improvements. <br />
                    We're open to value creative suggestions.<br />
                    Your feedback is invaluable to us, and it will help shape the future of DPRG.<br />
                    <br />
                    Our goal is to create a generator allows for safe and productive work.<br />
                    Highly reliable that meets the needs of developers and researchers across various domains.<br />
                    <h2> <strong>We appreciate your time and expertise in reviewing DPRG v1.0</strong></h2>
                    To access DPRG v1.0, please see details bellow
                    <br />
                    For bug reports, suggestions, or any questions, you can reach out to us using our contact form (which you find bellow)

                </div>


                {/* <h1>Examples</h1>
                <div className="sample-list">
                    <a className="sample" href="/dprg_sample/dprg_sample.zip" target="_blank">
                        <p>Download Example Package (116 Samples)</p>
                        <img src="/images/core/basic/download.png" className="download" alt="download"></img>

                    </a>
                    <a className="sample" href="/dprg_sample/DPRG_TestSet_experimental.zip" target="_blank">
                        <p>Download Experimental Package (2 Samples)</p>
                        <img src="/images/core/basic/download.png" className="download" alt="download"></img>
                    </a>

                    <a className="sample" href="/dprg_sample/DPRG2_normal_distribution.zip" target="_blank">
                        <p>Download Experimental DPRG.2 Normal Distribution (3 Samples)</p>
                        <img src="/images/core/basic/download.png" className="download" alt="download"></img>
                    </a>
                    

                    <div className="description">

                        RCv13f4-20230728_N1690673665545_L10_D16_D
                        <ul>
                            <li>RCv13f4-20230728 - technical version</li>
                            <li>N = 1690673665545 - seed number</li>
                            <li>L = 10 - size of the sequence</li>
                            <li>D = 16 - number of digits</li>
                            <li>D - decimal representation</li>
                        </ul>
                        * Experimental feature. Direct export of sequence to feed tests like TestU01, PractRand, TestSums, ENT, FIPS PUB 140-2.
                    </div>

                </div> */}


                <h1>Need samples? (DPRG1 Uniform Dist)</h1>

                <DPRGRequesterCMP />

                <FeedbackCmp />


                <h1>API Usage</h1>
                <div className="description-code">
                    <h2>Request Headers:</h2>
                    Authorization: YOUR_API_KEY<br />
                    Base URL: https://api.smart-fox-innovation.com/api/<br />
                    Demo Key: 5171637c6dd141cc1b874e98272dc0ebb901189e745ac151040670d7b014edd5<br />

                    <h2>Endpoints</h2>
                    <h4>1. Request to generate custom sequence by requestID for Parameters</h4>
                    <p>Endpoint: /dprg/api-dprg-request.php</p>
                    <h4>Request:</h4>

                    <p>Method: POST</p>
                    <p>Headers: Authorization: YOUR_API_KEY</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"n": INTEGER,</p>
                    <p>"k": INTEGER,</p>
                    <p>"l": INTEGER,</p>
                    <p>"d": INTEGER</p>
                    <p>&#125;</p>

                    <h4>Response:</h4>
                    <p>Status Code: 200 (OK)</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"requestID": "Unique Request ID"</p>
                    <p>&#125;</p>

                    <h4> 2. Check if requested set is ready by requestID </h4>
                    <p>Endpoint: /dprg/api-dprg-check.php</p>
                    <h4>Request:</h4>

                    <p>Method: POST</p>
                    <p>Headers: Authorization: YOUR_API_KEY</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"requestID": String,</p>
                    <p>&#125;</p>

                    <h4>Response:</h4>
                    <p>Status Code: 200 (OK)</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"requestID": "Unique Request ID",</p>
                    <p>"data": &#91;your sequence&#93;</p>
                    <p>&#125;</p>

                </div>


                <h1>DPRG Overview</h1>
                <div className="table_holder">
                    <table><thead><tr><th className="characteristic">Key Characteristics</th><th>Description</th></tr></thead>
                        <tbody>
                            <tr><td>Name</td><td>DPRG v1.0 </td></tr>
                            <tr><td>Randomness Source</td><td>Combination unique characteristic of complex patterns and software algorithms</td></tr>
                            <tr><td>Output Format</td><td>7, 16, 34 digits precision floating-point numbers (32, 64, 128 bits)</td></tr>
                            <tr><td>Supported Range</td><td>1 to 10 million (configurable)</td></tr>
                            <tr><td>Distribution</td><td>Uniform (work in progress to extend to other distributions)</td></tr>
                            <tr><td>Autocorrelation</td><td>Low correlation between consecutive numbers for lag from 1 to 5 (average autocorrelation &lt; 0.01)</td></tr>
                            <tr><td>Kolmogorov-Smirnov (uniform)</td><td>100% pass rate for kst with level &gt; 0.05 (avg &gt; 0.5), confirms high uniform value distribution</td></tr>
                            <tr><td>Chi-squared Test (uniform)</td><td>100% pass rate for chi test with level &gt; 0.05 (avg &gt; 0.5), confirms uniform value density distribution</td></tr>
                            <tr><td>Uniformity</td><td>Very close to perfect uniformity (average mean ~ 0.5)</td></tr>
                            <tr><td>Entropy</td><td>High entropy (average entropy &gt; 16 [10^7])</td></tr>
                            <tr><td>Standard Deviation</td><td>Spread from center value with Standard deviation at ~0.2886360</td></tr>
                            <tr><td>Diehard/NIST Tests</td><td>Passes Diehard and NIST statistical tests</td></tr>
                            <tr><td>Custom Tests</td><td>Rigorous tests exclusively designed for DPRG</td></tr>
                            <tr><td>Easy to use</td><td>Uses input n-for seed, k-output value size, l-for size, d for digits</td></tr>
                            <tr><td>Uniqueness</td><td>High unique level in generated batches equal to 100% up to 10 million</td></tr>
                            <tr><td>Uniqueness+</td><td>3 next runs of sequence with new seed give 100% unique digits for 10^5 size</td></tr>
                            <tr><td>Customization</td><td>Possible adjustment to client needs special version with unique characteristic</td></tr>
                            <tr><td>Performance</td><td>Generates approximately 200,000 numbers per second</td></tr>
                            <tr><td>Extensibility</td><td>Potential to add more statistical tests and distributions</td></tr>
                            <tr><td>Additional Features</td><td>Unique design make it possible to customize to high degree for customer needs</td></tr>
                        </tbody>
                    </table>
                </div>


                <h1>DPRG High Level Description</h1>

                <div className="description">
                    <h2>DPRG (Dynamic Pseudo Random Number Generator) Analysis:</h2>

                    Algorithm Description:
                    The DPRG is a pseudo-random number generator designed to produce deterministic sequences of seemingly random numbers.
                    It employs a well-defined algorithm that takes an initial seed value and deterministically generates subsequent random numbers based on this seed.
                    The generator aims to achieve uniformity and statistical properties resembling true randomness.

                    <h2>Seed Selection:</h2>
                    The DPRG requires an initial seed value to start the random number generation process. The seed can be any integer, and it guarantees the reproducibility of the sequence.
                    The generator provides flexibility in seed selection, allowing users to control the starting point of the sequence.

                    <h2>Reproducibility and Determinism:</h2>
                    One of the key features of the DPRG is its ability to produce reproducible sequences of random numbers for a given seed.
                    This deterministic behavior ensures that the same seed will always generate the same sequence, making it suitable for scenarios requiring repeatable results.

                    <h2>Statistical Tests:</h2>
                    The DPRG undergoes extensive statistical testing to verify its random number generation properties.
                    The generator has been subject to multiple statistical tests, including Birthday Spacings Test, Kolmogorov-Smirnov (K-S) test, Chi-squared test, and entropy analysis.
                    The results of these tests demonstrate that the DPRG meets the expected statistical properties of randomness.

                    <h2>Performance and Efficiency:</h2>
                    The DPRG boasts good performance and efficiency in generating random numbers.
                    It can produce a large number of random values quickly, making it suitable for various computational tasks.

                    <h2>Independence of Runs:</h2>
                    The DPRG demonstrates the ability to produce independent runs of random sequences.
                    This property is crucial for many applications, such as simulations, cryptography, and secure key generation.

                    <h2>Large Sequence Support:</h2>
                    The DPRG can generate random sequences of varying sizes, from small sequences of 10 elements to much larger ones containing millions of elements.
                    The generator maintains its statistical properties across different sequence lengths.

                    <h2>Adaptability:</h2>
                    The DPRG offers adaptability to different applications. It can be used for simulations, cryptographic algorithms, statistical analysis, and other scenarios where random numbers are essential.

                    <h2>Compatibility:</h2>
                    The DPRG is based on java implementation (JVM 20+). With Api integration offers not restricted access for all platforms.
                    RESTfull API provides standardized modern way of communication.

                    <h2>Public Testing and Review:</h2>
                    The DPRG has undergone rigorous testing, including Diehard and NIST tests, which are well-established standards for evaluating random number generators.
                    The generator has consistently passed these tests, providing evidence of its high-quality randomness.

                    <h2>Security</h2>
                    How it can be used in cryptographic applications and any measures taken to ensure the generator's security and reliability.
                    This aspect would need to be align with individual client needs. Wide range of posable solutions adjusted to every one.
                    DPRG has a set of basic tests to test quality of generated sequences.

                    <h2>Performance</h2>
                    Currently the DPRG v1.0 has 200,000\s (test platform Intel Core i7 8700K, 4.3 GHz, DDR 4 32Gib)

                    <h2>Observations</h2>
                    Interesting fact is that as  generated sequence gets longer the random level gets better characteristics and more uniform spread.

                    <h2>License</h2>
                    DPRG is planned as a cloud service for commercial use. For security aspect other ways my be developed to fit needs of the final customer.

                    <h2>Continued Development:</h2>
                    The DPRG is actively being developed, and its creator is committed to ongoing improvements and enhancements.
                    The generator's open-source nature encourages collaboration and community feedback.
                    Next steps working on delivery of different Distributions

                    <br />
                    <br />Continued Development started on DPRG 2.0 will include:
                    <br />- improved statistical properties for smaller sets (1-100) ~10% improved
                    <br />- increased "butterfly effect" for given n (large set variance)
                    <br />- aiming to deliver normal distribution with high quality (work in progress do deliver 0 to 1 normal distribution)
                    <br />
                    <br />

                    In summary, the DPRG is a Dynamic pseudo-random number generator that exhibits strong statistical properties and passes various tests for randomness.
                    It offers reproducibility, efficiency, and adaptability to different applications, making it a valuable tool for various computational tasks.
                    Its thorough testing and ongoing development underscore its reliability and suitability for a wide range of applications.

                </div>
                
                <h1>DPRG 2.0 Normal Distribution</h1>
                <div className="histogram_holder">
                    <div className="histogram_name">Histogram for size 10^5</div>
                    <img src="/images/dprg_info/dprg2_normal.png" className="hist" alt="histogram"></img>
                </div>


                <h1>Histogram</h1>
                <div className="histogram_holder">
                    <div className="histogram_name">Histogram for size 10</div>
                    <img src="/images/dprg_info/HistogramL10D16.png" className="hist" alt="histogram"></img>
                    <div className="histogram_name">Histogram for size 100</div>
                    <img src="/images/dprg_info/HistogramL100D16.png" className="hist" alt="histogram"></img>
                    <div className="histogram_name">Histogram for size 10000</div>
                    <img src="/images/dprg_info/HistogramL10000D16.png" className="hist" alt="histogram"></img>
                    <div className="histogram_name">Histogram for size 100000</div>
                    <img src="/images/dprg_info/HistogramL100000D16.png" className="hist" alt="histogram"></img>
                    <div className="histogram_name">Histogram for size 1000000</div>
                    <img src="/images/dprg_info/HistogramL1000000D16.png" className="hist" alt="histogram"></img>
                    <div className="histogram_name">Histogram for size 10000000</div>
                    <img src="/images/dprg_info/HistogramL10000000D16.png" className="hist" alt="histogram"></img>

                </div>

                <h1>Scatter Plot</h1>
                <div className="scatter_holder">
                    <div className="scatter_name">Scatter Plot for size 10</div>
                    <img src="/images/dprg_info/ScatterPlotL10D16.png" className="hist" alt="histogram"></img>
                    <div className="scatter_name">Scatter Plot for size 100</div>
                    <img src="/images/dprg_info/ScatterPlotL100D16.png" className="hist" alt="histogram"></img>
                    <div className="scatter_name">Scatter Plot for size 1000</div>
                    <img src="/images/dprg_info/ScatterPlotL1000D16.png" className="hist" alt="histogram"></img>
                    <div className="scatter_name">Scatter Plot for size 10000</div>
                    <img src="/images/dprg_info/ScatterPlotL10000D16.png" className="hist" alt="histogram"></img>
                    <div className="scatter_name">Scatter Plot for size 100000</div>
                    <img src="/images/dprg_info/ScatterPlotL100000D16.png" className="hist" alt="histogram"></img>
                    <div className="scatter_name">Scatter Plot for size 1000000</div>
                    <img src="/images/dprg_info/ScatterPlotL1000000D16.png" className="hist" alt="histogram"></img>
                    <div className="scatter_name">Scatter Plot for size 10000000</div>
                    <img src="/images/dprg_info/ScatterPlotL10000000D16.png" className="hist" alt="histogram"></img>

                </div>
                <h1>Build-in Statistics</h1>
                <div className="statistics_section">

                    <table className="tg">
                        <thead>
                            <tr>
                                <th className="tg-fgy3">digits</th>
                                <th className="tg-fgy3">size</th>
                                <th className="tg-fgy3">sets</th>
                                <th className="tg-fgy3">unique\sample</th>
                                <th className="tg-fgy3">avg mean</th>
                                <th className="tg-fgy3">avg max</th>
                                <th className="tg-fgy3">avg min</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">0.5161</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.07190</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">100</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">100</td>
                                <td className="tg-fgy3">0.5006</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.01560</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">1000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">1000</td>
                                <td className="tg-fgy3">0.5000</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.00310</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.5000</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.00180</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">100000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">100000</td>
                                <td className="tg-fgy3">0.5000</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.00010</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">1000000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">1000000</td>
                                <td className="tg-fgy3">0.5000</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.00009</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10000000</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">10000000</td>
                                <td className="tg-fgy3">0.5000</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.00001</td>
                            </tr>
                        </tbody>
                    </table>


                    <table className="tg">
                        <thead>
                            <tr>
                                <th className="tg-fgy3">digits</th>
                                <th className="tg-fgy3">size</th>
                                <th className="tg-fgy3">sets</th>
                                <th className="tg-fgy3">avg std dev</th>
                                <th className="tg-fgy3">avg kst</th>
                                <th className="tg-fgy3">avg chi</th>
                                <th className="tg-fgy3">avg entropy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.3148</td>
                                <td className="tg-fgy3">0.0868</td>
                                <td className="tg-fgy3">0.6134</td>
                                <td className="tg-fgy3">2</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">100</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.2912</td>
                                <td className="tg-fgy3">0.9234</td>
                                <td className="tg-fgy3">0.8803</td>
                                <td className="tg-fgy3">4</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">1000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.2898</td>
                                <td className="tg-fgy3">0.9359</td>
                                <td className="tg-fgy3">0.8017</td>
                                <td className="tg-fgy3">7</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.2893</td>
                                <td className="tg-fgy3">0.9299</td>
                                <td className="tg-fgy3">0.7510</td>
                                <td className="tg-fgy3">9</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">100000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.2886</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">11</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">1000000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">0.2886</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.9758</td>
                                <td className="tg-fgy3">14</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10000000</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">0.2886</td>
                                <td className="tg-fgy3">0.9999</td>
                                <td className="tg-fgy3">0.9985</td>
                                <td className="tg-fgy3">16</td>
                            </tr>
                        </tbody>
                    </table>


                    <table className="tg">
                        <thead>
                            <tr>
                                <th className="tg-fgy3">digits</th>
                                <th className="tg-fgy3">size</th>
                                <th className="tg-fgy3">sets</th>
                                <th className="tg-fgy3">acf(lag 1)</th>
                                <th className="tg-fgy3">acf(lag 2)</th>
                                <th className="tg-fgy3">acf(lag 3)</th>
                                <th className="tg-fgy3">acf(lag 4)</th>
                                <th className="tg-fgy3">acf(lag 5)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">-0.1099</td>
                                <td className="tg-fgy3">-0.1753</td>
                                <td className="tg-fgy3">-0.2224</td>
                                <td className="tg-fgy3">+0.0182</td>
                                <td className="tg-fgy3">+0.0830</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">100</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">-0.0077</td>
                                <td className="tg-fgy3">-0.0021</td>
                                <td className="tg-fgy3">-0.0158</td>
                                <td className="tg-fgy3">+0.0062</td>
                                <td className="tg-fgy3">-0.0009</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">1000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">-0.0039</td>
                                <td className="tg-fgy3">-0.0039</td>
                                <td className="tg-fgy3">-0.0032</td>
                                <td className="tg-fgy3">-0.0017</td>
                                <td className="tg-fgy3">+0.0006</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">+0.0149</td>
                                <td className="tg-fgy3">-0.0043</td>
                                <td className="tg-fgy3">+0.0005</td>
                                <td className="tg-fgy3">+0.0185</td>
                                <td className="tg-fgy3">+0.0065</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">100000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">+0.0063</td>
                                <td className="tg-fgy3">-0.0023</td>
                                <td className="tg-fgy3">+0.0005</td>
                                <td className="tg-fgy3">+0.0026</td>
                                <td className="tg-fgy3">+0.0015</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">1000000</td>
                                <td className="tg-fgy3">10000</td>
                                <td className="tg-fgy3">+0.0004</td>
                                <td className="tg-fgy3">-0.0023</td>
                                <td className="tg-fgy3">+0.0005</td>
                                <td className="tg-fgy3">-0.0006</td>
                                <td className="tg-fgy3">-0.0002</td>
                            </tr>
                            <tr>
                                <td className="tg-fgy3">16</td>
                                <td className="tg-fgy3">10000000</td>
                                <td className="tg-fgy3">10</td>
                                <td className="tg-fgy3">+0.0005</td>
                                <td className="tg-fgy3">+0.0002</td>
                                <td className="tg-fgy3">+0.0002</td>
                                <td className="tg-fgy3">+0.0000</td>
                                <td className="tg-fgy3">+0.0000</td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>



        );

        /*function addExampleFile($sample, $index) {
            return <Link className="sample" key={$index} to={"https://smart-fox-innovation.com/dprg_sample/" + $sample.file}>{$sample.name + ' digits ' + $sample.digits + ' size ' + $sample.size}</Link>

        }*/

    }

}

export default DPRGPage;


/**
 * 
                <section>Statistical Properties</section>
                <section>Sizes</section>
                <section>Uniques</section>


                <section>Value Ranges</section>
                <section>Digits</section>
                <section>Entropy</section>
                <section>Chi-squared Test (uniform) bins (1+log2(n))</section>
                <section>Kolmogorov-Smirnov Test (uniform)</section>
                <section>Spectral Analysis</section>
                <section>Birthday Spacings Test</section>
                <section>Runs Spacings Test</section>


                <section>Auto Correlation Lag step 1 (acf)</section>
                <section>Auto Correlation Lag step 2 (acf)</section>
                <section>Auto Correlation Lag step 3 (acf)</section>
                <section>Auto Correlation Lag step 4 (acf)</section>
                <section>Auto Correlation Lag step 5 (acf)</section>

                <section>Served Distributions</section>
                <section>Speed</section>
                <section>Benchmarks</section>
 */