import { Component } from "reactn";
import './ProductsCmp.scss';
import { Link } from 'react-router-dom';


class ProductsCmp extends Component {
    state = {
        products: [
            {
                image: '/images/products/dprg_logo_250x250.webp',
                title: 'DPRG',
                subtitle: 'Pseudo Random Number Generator',
                description: 'Our solution is a state-of-the-art solution designed to meet the most demanding requirements. Leveraging advanced algorithms, it delivers high-quality random numbers that pass stringent statistical randomness tests. This product is ideal for applications in cryptography, statistical sampling, computer simulations, and more. Experience unparalleled randomness, where quality meets unpredictability.',
                disable: false,
                link: "DPRG/"
            },
            {
                image: '/images/products/swiftlock-250x250.webp',
                title: 'SwiftLock',
                subtitle: 'Security for Your Digital World',
                description: 'Swift Lock is a cutting-edge security solution designed to safeguard your digital assets and privacy. Leveraging state-of-the-art encryption and authentication methods, Swift Lock ensures robust protection against unauthorized access. With a focus on user-friendly interface and seamless integration, Swift Lock empowers you to take control of your online security, providing peace of mind in an ever-evolving digital landscape.',
                disable: false,
                link: "swiftlock/"
            },
            {
                image: '/images/products/knapsack-250x250.webp',
                title: 'Knapsack',
                subtitle: 'Packing Optimization Tool',
                description: "The Knapsack Calculator is a state-of-the-art packing optimization tool designed for complex, real-world applications. This powerful algorithm efficiently tackles the multifaceted challenge of packing a large number of items with varying characteristics under multiple constraints. The Knapsack Calculator is not just a tool; it's a comprehensive solution for modern, efficient, and intelligent packing, designed to meet the demanding needs of today's logistics and supply chain challenges.",
                disable: false,
                link: "knapsack/"

            },
            {
                image: '/images/products/aBit-250x250.webp',
                title: 'aBit',
                subtitle: 'Data Transmission Technology',
                description: 'Introducing a groundbreaking approach to data transmission technology that promises to revolutionize the way we think about connectivity. Our innovative method focuses on a fundamentally different aspect of electrical behavior to achieve higher data throughput (x1000), improved efficiency, and reduced infrastructure costs. Compatible with existing cabling systems like RJ45, this new technology is designed for seamless integration into current networks. It offers the prospect of substantially increased data speeds while maintaining high levels of integrity and reliability',
                disable: false,
                link: "aBit/"
            },
            {
                image: '/images/products/ent-250x250.webp',
                title: 'ENT (GEN-VR)',
                subtitle: 'Data Security Technology',
                description: 'ENT (Encoded Number Theory) is a novel approach to data security, offering a theoretically unbreakable mechanism unless all the key information is known. While it may not serve as a compression tool, its unique properties make it an exceptional candidate for secure data storage and transmission.',
                disable: false,
                link: "ent/"
            }, 
            {
                image: '/images/products/altera_logo_250x250.webp',
                title: 'ALTERA',
                subtitle: 'Omni-Commerce Platform',
                description: 'Discover the future of commerce with Altera, our cutting-edge omni-commerce platform. Altera provides a seamless shopping experience across all channels, integrating online and offline touchpoints in real-time. With its robust capabilities, including AI-driven personalization, advanced inventory management, and comprehensive analytics, Altera empowers businesses to elevate their customer experience, streamline operations, and drive growth. Transform your commerce strategy with Altera, where every channel becomes a customer opportunity.',
                disable: true,
                link: ""

            },

            {
                image: '/images/products/swift_fox_logo_250x250.webp',
                title: 'SWIFT FOX',
                subtitle: 'Advanced Route Optimization',
                description: "Swift fox finds optimal paths, is an advanced route optimization solution that revolutionizes the way businesses manage their logistics and operations. Built on sophisticated algorithms and machine learning, OptimaRoute solves the Traveling Salesman Problem with superior efficiency, offering the most optimal routes for multiple destinations. Whether you're in delivery, transportation, or any industry requiring complex routing, OptimaRoute minimizes travel time, reduces operational costs, and maximizes productivity. Transform your operations with Swift Fox, where every route is the smart route.",
                disable: true,
                link: ""

            },
            {
                image: '/images/products/nova_prism_logo_250x250.webp',
                title: 'NOVA PRISM',
                subtitle: 'Replenishment & Planogram Solution',
                description: "Introducing Nova Prism, a cutting-edge planogram solution designed to revolutionize your retail space management. Nova Prism employs intelligent algorithms to optimize product placement, enhancing shopper engagement and boosting sales. With its user-friendly interface and robust analytical capabilities, Nova Prism provides valuable insights into shopper behavior, enabling retailers to maximize the effectiveness of their retail space. Streamline your merchandising process, improve inventory turnover, and drive growth with Nova Prism, the future of retail space optimization.",
                disable: true,
                link: ""
            },
            {
                image: '/images/products/smart-contract-logo-250x250.webp',
                title: 'Smart Contracts',
                subtitle: 'Managing Contracts is Simple',
                description: 'Smart Contracts revolutionize the way we manage agreements by automating, securing, and making them transparent. Built on blockchain technology, these digital contracts execute predefined actions without the need for intermediaries. They\'re versatile, adaptable across various sectors like finance, healthcare, and public administration. Experience hassle-free, transparent dealings with Smart Contracts.',
                disable: false,
                link: "SMART-CONTRACT/"
            }
        ]
    }



    render() {
        let items = this.state.products.map(ProductCard);

        return (
            <div className="ProductsCmp">
                <h1>Our Products</h1>
                <div className="product-list">
                    {items}
                </div>
            </div>
        );


        // ProductCard Component
        function ProductCard(product, index) {

            let disable = product.disable ? 'disable' : '';
            let buttonText = product.disable ? 'Coming soon' : 'Learn More';

            return (
                <div className="product-card" key={index}>
                    <Link to={product.link} className="link">
                        <div className="title">
                            <h3>{product.title}</h3>
                        </div>

                        <div className="content">
                            <img src={product.image} alt={product.title} />
                            <h4>{product.subtitle}</h4>
                            <button className={"product-button " + disable}>{buttonText}</button>

                            <div className="texts">{product.description}</div>

                        </div>
                    </Link>
                </div>

            );
        }
    }
}

export default ProductsCmp;



