import { Component, React } from "reactn";
import './FeedbackCmp.scss';
import axios from "axios";

class FeedbackCmp extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            send: 'Send Message',
            status_class: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        axios.post('https://api.smart-fox-innovation.com/api/api-contact.php', {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message
        })
            .then((response) => {
                this.setState({name:''});
                this.setState({email:''});
                this.setState({message:''});

                this.setState({send: "Success Message Send", status_class: 'ok'});
            })
            .catch((error) => {
                this.setState({send: "Error while sending the message, try again?", status_class: 'nok'});
                console.error(error);
            });
    }

    render() {
        return (
            <div className="FeedbackCmp">
                <h1>Contact Us</h1>
                <form onSubmit={this.handleSubmit}>
                    <input type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder="Your Name" required />
                    <input type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder="Your Email" required />
                    <textarea name="message" value={this.state.message} onChange={this.handleChange} placeholder="Your Message" required></textarea>
                    <button type="submit" className={"button " + this.state.status_class}>{this.state.send}</button>
                    
                </form>
            </div>

        );
    }

    //

}

export default FeedbackCmp;