
import React, { Component } from 'reactn'
import './HomePage.scss';
import Timeline from '../../elements/timeline/TimelineCmp';
import AboutCmp from '../../elements/about/AboutCmp';
import FeedbackCmp from '../../elements/feedback/FeedbackCmp';
import ValuesCmp from '../../elements/values/ValuesCmp';
import ProductsCmp from '../../elements/products/ProductsCmp';
import NewsletterCMP from '../../elements/newsletter/NewsletterCMP';

class HomePage extends Component {

    /*  constructor(props) {
          super(props);
      }
  
      state = {  }*/


    componentDidMount() {
        document.body.scrollTop = 0;
    }

    // <div className='line2'>Smart Fox Innovation</div>
                        
    render() {
        return (
            <div className="HomePage">

                <div className='slide'>
                    <div className='image-layer' >
                        <div id='spin360' className='spin360'></div>
                    </div>

                    <div className='text'>
                        <div className='line1'>Welcome to </div>
                        <div className='line3'>New Way of Thinking</div>
                        <div className='line3'>A Unicorn Startup!</div>
                    </div>
                    
                    <div className="arrows"></div>
                </div>

                <nav id="anchor-litter"></nav>
             

                <h1>What's new</h1>
                <div className='separator'></div>

                <nav id="anchor-timeline"></nav>
                <Timeline></Timeline>

                <nav id="anchor-values"></nav>
                <ValuesCmp></ValuesCmp>

                <nav id="anchor-products"></nav>
                <ProductsCmp></ProductsCmp>

                <nav id="anchor-about"></nav>
                <AboutCmp></AboutCmp>

        

                <nav id="anchor-newsletter"></nav>
                <NewsletterCMP></NewsletterCMP>


                <nav id="anchor-contact"></nav>
                <FeedbackCmp></FeedbackCmp>

            </div>
        );
    }



}

export default HomePage;