
import React, { Component } from 'reactn'
import './OverlayCmp.scss';

class OverlayCmp extends Component {
    constructor(props) {
        super(props);

        this.closeCallback = this.props.closeCallback;

        this.close = this.close.bind(this);
        this.show = this.show.bind(this);

    }

    state = {
        show: true
    }

    render() {


        let showContent = this.state.show ? 'box' : 'none';

        //console.log(this.state.show);
        //console.log(showContent);

        const divStyle = {
            display: showContent 
        };

        return (
            <div id="overlayID" className='OverlayCmp' loading='lazy' style={divStyle}>
                <div className="close-btn" onClick={this.close}>&times;</div>
                <div className="overlay-content">
                </div>
            </div>
        );
    }

    openNav() {
        document.getElementById("overlayID").style.display = "box";
    }

    closeNav() {
        document.getElementById("overlayID").style.display = "none";
    }

    
    show() {
        //this.setState({ show: true });
        //console.log(this.state.show);
    }

    close() {
        //this.setState({ show: false });
        this.closeCallback();
        //console.log(this.state.show);
    }
}



export default OverlayCmp;