import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './ABitPage.scss'
import FeedbackCmp from "../../elements/feedback/FeedbackCmp";
import MovieCMP from "../../elements/movie/MovieCMP";

class ABitPage extends Component {
  
    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        //let sample_list = this.state.dprg_sample.map(addExampleFile);


        return (
            <div className="ABitPage">
                <div className="title">aBit<h5>Data Transmission Technology</h5></div>

                <div className="info-box">
                    <img src="/images/products/aBit-250x250.webp" alt="DPRG" className="img" />
                    <div className="description">
                    Introducing a groundbreaking approach to data transmission technology that promises to revolutionize the way we think about connectivity. Our innovative method focuses on a fundamentally different aspect of electrical behavior to achieve higher data throughput, improved efficiency, and reduced infrastructure costs. Compatible with existing cabling systems like RJ45, this new technology is designed for seamless integration into current networks. It offers the prospect of substantially increased data speeds while maintaining high levels of integrity and reliability. Stay tuned for more details as we move closer to redefining the future of data transmission.
                    </div>
                </div>

                <MovieCMP videoName="aBit-public.mp4"></MovieCMP>
              
                <FeedbackCmp />

                </div>


        )};



}

export default ABitPage;
