//import { setGlobal } from 'reactn'
import axios from 'axios';
import CoreApi from './core_api';

export default class CoreDPRG {

    static getRequestID(callback, n, l, k, d) {
        if (n !== undefined && l !== undefined && k !== undefined && d !== undefined) {
            let json = JSON.stringify({ "n": n, "l": l, "k": k, "d": d });

            let config = { headers: { Authorization: CoreApi.API_KEY } };
            
            axios.post(CoreApi.getRequestID, json, config)
                .then(res => {
                    res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrieve any data")
                }).catch(function (err) {
                    console.log(err.message);
                })
        }


    }

    static getRequestCheck(callback, requestID) {
        if (requestID !== undefined) {
            let json = JSON.stringify({ "requestID": requestID });

            let config = { headers: { Authorization: CoreApi.API_KEY } };
            
            axios.post(CoreApi.getRequestCheck, json, config)
                .then(res => {
                    res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrieve any data")
                }).catch(function (err) {
                    console.log(err.message);
                })
        }else{
            console.log("Error requestID is undefined");
        }


    }


    
}