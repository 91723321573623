
import React, { Component } from 'reactn'
import OverlayCmp from '../overlay/OverlayCmp';
import './TimelineCmp.scss';
import PageLinks from '../../pages/PageLinks';

import { Link } from 'react-router-dom';

class Timeline extends Component {
    constructor(props) {
        super(props);

        this.showOverlay = this.showOverlay.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.getTimeItem = this.getTimeItem.bind(this);

    }
    state = {
        overlay: false

    }

    //{this.getTimeItem(2023, 'June', 'dprg_250x250.webp', 'DPRG - Random Number Generator development', '', 'DPRG')}

    render() {

        let overlay = this.state.overlay ? <OverlayCmp closeCallback={this.hideOverlay}></OverlayCmp> : '';
        //    {this.getTimeItem(2024, 'January', 'Saas_serviceB_250x250.webp', 'DPRG Saas service', '', 'DPRG')}


        return (

            <div className='TimelineCmp'>

                {this.getTimeItem(2023, 'July', 'review_public_250x250.webp', 'DPRG Public review', '', 'DPRG', PageLinks.DPRG)}

                {this.getTimeItem(2023, 'September', 'community_feedback_review_250x250.webp', 'DPRG Community feedback review', '', 'DPRG', PageLinks.DPRG)}

                {this.getTimeItem(2023, 'October', 'smart-contract-logo-250x250.webp', 'Smart Contract Introduction', '', 'SMART-CONTRACTS', PageLinks.SMARTCONTRACTS)}

                {this.getTimeItem(2023, 'November', 'swiftlock-250x250.webp', 'Swift Lock', '', 'SwiftLock', PageLinks.SWIFTLOCK)}

                {this.getTimeItem(2024, 'March', 'traveling_sales_man.webp', 'New TSP Solution', '', 'SWIFT FOX', '')}

                {overlay}
            </div>

        );
    }

    showOverlay() {
        this.setState({ overlay: true });
    }

    hideOverlay() {
        this.setState({ overlay: false });
    }

    getTimeItem($year, $month, $img, $title, $text, $blueText, $link) {
        let divStyle = {
            backgroundImage: 'url(/images/time-line/' + $img + ')'
        };

        // return <div className="tl-item" onClick={this.showOverlay}>
        return <div className="tl-item" >
            <Link to={$link} className='link'>
                <div className="tl-bg" style={divStyle}></div>


                <div className="tlh-content">
                    <h1 className="f3 text--accent ttu">{$blueText}</h1>
                </div>

                <div className="tl-year">
                    <p className="f2">{$year}</p>
                    <p className="f2">{$month}</p>
                </div>


                <div className="tl-content">
                    <h1 className="f3 text--accent ttu">{$title}</h1>
                    <p>{$text}</p>
                </div>

            </Link>
        </div>
    }

}






export default Timeline;