import { Component } from "reactn";
import './KnapsackRequesterCMP.scss'
import CoreKNAPSACK from "../../../api/core_knapsack";

class KnapsackRequesterCMP extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requestID: "",
            capacity: 10,
            maxItemsPerBox: 12,
            maxVolumePerBox: 120,
            W: "1.4;2.3;3.2;4.1",
            V: "100;120;9.99;4.99",
            S: "10;12;33;1",
            I: "A;B;C;D",
            uploadInfo: ''

        }

        this.callbackRequestID = this.callbackRequestID.bind(this);
        this.callRequestID = this.callRequestID.bind(this);
        this.callbackRequestCheck = this.callbackRequestCheck.bind(this);
        this.callRequestCheck = this.callRequestCheck.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.PadWithZeros = this.PadWithZeros.bind(this);
    }

    callbackRequestID(data) {
        this.setState({ requestID: data.requestID });
    }

    callRequestID() {
        CoreKNAPSACK.getRequestID(this.callbackRequestID, this.state.capacity, this.state.maxItemsPerBox, this.state.maxVolumePerBox, this.state.W, this.state.V, this.state.S, this.state.I);
    }

    PadWithZeros(value, length) {
        return value.toString().padEnd(length, '0');//.padStart(length, '0');
    }

    callbackRequestCheck(data) {
        if (data.data !== undefined && data.data !== undefined) {
            //console.log(data);
            // Convert the JSON object to a JSON string
            //const jsonData = JSON.parse(data, null, 2);

            // Create a Blob with the JSON string as data
            const dataArray = data.data;
            const separator = ''; // Adjust the separator as needed

            // Left-pad each element in the data array and add to the result array
            const paddedData = dataArray;//.map((value) => (value)));
            const arrayAsString = paddedData.join(separator);

            const blob = new Blob([arrayAsString], { type: 'text/plain' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            //this.state.setState({ downloadLink: url });

            const link = document.createElement('a');
            link.href = url;
            link.download = 'knapsack_list.txt'; // File name for the download
            link.click();

            // Clean up the temporary URL and link
            URL.revokeObjectURL(url);

        } else {

            this.setState({ uploadInfo: data.message });
        }

    }

    callRequestCheck() {
        this.setState({ uploadInfo: '' });
        CoreKNAPSACK.getRequestCheck(this.callbackRequestCheck, this.state.requestID);
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        return (
            <div className="KnapsackRequesterCMP">

                <form id="requestID">
                    <div className="set_holder">
                        <label htmlFor="capacity" className="label">Max Capacity:</label>
                        <input type="text" id="capacity" name="capacity" required className="input" value={this.state.capacity} onChange={this.handleChange} />
                    </div>

                    <div className="set_holder">
                        <label htmlFor="maxItemsPerBox" className="label">Max Items Per Box:</label>
                        <input type="text" id="maxItemsPerBox" name="maxItemsPerBox" required className="input" value={this.state.maxItemsPerBox} onChange={this.handleChange} />
                    </div>
                    <div className="set_holder">
                        <label htmlFor="maxVolumePerBox" className="label">Max Volume Per Box:</label>
                        <input type="text" id="maxVolumePerBox" name="maxVolumePerBox" value={this.state.maxVolumePerBox} className="input" onChange={this.handleChange} />
                    </div>

                    <div className="set_holder">
                        <label htmlFor="W" className="label">W: weights w1;w2...</label>
                        <input type="text" id="W" name="W" value={this.state.W} className="input" onChange={this.handleChange} />
                    </div>
                    <div className="set_holder">
                        <label htmlFor="V" className="label">V: value v1;v2...</label>
                        <input type="text" id="V" name="V" value={this.state.V} className="input" onChange={this.handleChange} />
                    </div>
                    <div className="set_holder">
                        <label htmlFor="S" className="label">S: volume s1;s2...</label>
                        <input type="text" id="S" name="S" value={this.state.S} className="input" onChange={this.handleChange} />
                    </div>
                    <div className="set_holder">
                        <label htmlFor="I" className="label">I: item ID i1;i2...</label>
                        <input type="text" id="I" name="I" value={this.state.I} className="input" onChange={this.handleChange} />
                    </div>
                    <div className="submit" onClick={this.callRequestID}>Request Packing Sequence</div>
                </form>
                <label className="label2">Request ID:</label>
                <input type="text" id="requestID" name="requestID" className="request-label" value={this.state.requestID} onChange={this.handleChange} />

                <br />
                <div className="submit" onClick={this.callRequestCheck}>Check Request & Download</div>
                <br />
                <div >* For security all responses are processed by our staff so please be patient. Process can take some time</div>
                <div >{this.state.uploadInfo}</div>
            </div>
        );
    }
}

export default KnapsackRequesterCMP;