import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './KnapsackPage.scss'
import FeedbackCmp from "../../elements/feedback/FeedbackCmp";
import MovieCMP from "../../elements/movie/MovieCMP";
import KnapsackRequesterCMP from "../../elements/knapsack_requester/KnapsackRequesterCMP";


class KnapsackPage extends Component {
    state = {


    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        return (
            <div className="KnapsackPage">
                <div className="title">Knapsack (white-label)<h5>Calculator v1.3 Alpha: Advanced Packing Optimization Tool</h5></div>

                <div className="info-box">
                    <img src="/images/products/knapsack-250x250.webp" alt="KNAPSACK" className="img" />
                    <div className="description">
                        The Knapsack Calculator v1.3 Alpha is a state-of-the-art packing optimization tool designed for complex, real-world applications.<br /> This powerful algorithm efficiently tackles the multifaceted challenge of packing a large number of items with varying characteristics under multiple constraints.<br />
                    </div>
                </div>

                <MovieCMP videoName="knapsackv2.mp4" ></MovieCMP>

                <div className="description">
                    <h1>We are proud to announce the release of Knapsack 1.3 Alpha (white-label solution)  </h1>

                    <strong>Multi-Constraint Optimization:</strong><br /> Capable of handling constraints on weight, volume, and item count per box, ensuring maximum utilization of packing space.<br /><br />
                    <strong>Item Attribute Sensitivity:</strong><br /> Accounts for unique item attributes such as fragility and durability, alongside traditional metrics like weight and value, making it ideal for diverse packing needs.<br /><br />
                    <strong>Scalable Performance:</strong><br /> Demonstrates exceptional efficiency and scalability, processing up to 100 items in milliseconds, a testament to its capability to handle large-scale operations.<br /><br />
                    <strong>Real-World Applicability:</strong><br /> With its sophisticated approach, it is well-suited for industries like logistics, e-commerce, and supply chain management, where packing efficiency is crucial.<br /><br />
                    <strong>User-Friendly Interface:</strong><br /> Designed for ease of use, allowing quick input and clear interpretation of packing solutions.<br /><br />
                    <strong>White Label Solution:</strong><br /> Available for white-labeling, offering businesses the opportunity to brand and integrate this powerful tool into their existing systems seamlessly.<br /><br />
                    <br />
                    <strong>The Knapsack Calculator v1.3 Alpha is not just a tool;</strong> it's a comprehensive solution for modern, efficient, and intelligent packing, designed to meet the demanding needs of today's logistics and supply chain challenges.<br />


                </div>



                <h1>Need some checking?</h1>

                <KnapsackRequesterCMP />

                <FeedbackCmp />


                <h1>API Usage</h1>
                <div className="description-code">
                    <h2>Request Headers:</h2>
                    Authorization: YOUR_API_KEY<br />
                    Base URL: https://api.smart-fox-innovation.com/api/<br />
                    Demo Key: 5171637c6dd141cc1b874e98272dc0ebb901189e745ac151040670d7b014edd5<br />

                    <h2>Endpoints</h2>
                    <h4>1. Request to generate custom sequence by requestID for Parameters</h4>
                    <p>Endpoint: /knapsack/api-knapsack-request.php</p>
                    <h4>Request:</h4>

                    <p>Method: POST</p>
                    <p>Headers: Authorization: YOUR_API_KEY</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"capacity": DECIMAL,</p>
                    <p>"maxItemsPerBox": INTEGER,</p>
                    <p>"maxVolumePerBox": DECIMAL,</p>
                    <p>"W": DECIMAL LIST separator ; - weights</p>
                    <p>"V": DECIMAL LIST separator ; - values</p>
                    <p>"S": DECIMAL LIST separator ; - volumes</p>
                    <p>"I": String LIST separator ; - items ID</p>
                    <p>&#125;</p>

                    <h4>Response:</h4>
                    <p>Status Code: 200 (OK)</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"requestID": "Unique Request ID"</p>
                    <p>&#125;</p>

                    <h4> 2. Check if requested set is ready by requestID </h4>
                    <p>Endpoint: /knapsack/api-knapsack-check.php</p>
                    <h4>Request:</h4>

                    <p>Method: POST</p>
                    <p>Headers: Authorization: YOUR_API_KEY</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"requestID": String,</p>
                    <p>&#125;</p>

                    <h4>Response:</h4>
                    <p>Status Code: 200 (OK)</p>
                    <p>Body (JSON):</p>
                    <p>&#123;</p>
                    <p>"requestID": "Unique Request ID",</p>
                    <p>"data": &#91;your sequence&#93;</p>
                    <p>&#125;</p>

                </div>

            </div>



        );

    }

}

export default KnapsackPage;
