import { Component } from "reactn";
import "./DownloadCMP.scss";
import PropTypes from 'prop-types';


class DownloadCMP extends Component {
    /*constructor(props) {
        super(props);
    }*/


    state = {}
    render() {
        return (

            <div className="DownloadCMP">
                <div className="download-cmp-title">{this.props.Name}</div>
                <a className="download-cmp-link" href={this.props.filePath + this.props.fileName} target="_blank" rel="noreferrer">
                    <img src="/images/core/basic/download.png" className="download" alt={this.props.Name}></img>
                </a>

            </div>
        );
    }
}

DownloadCMP.propTypes = {
    filePath: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired
};


export default DownloadCMP;

