import { Component } from "reactn";
import './DPRGRequesterCMP.scss'
import CoreDPRG from "../../../api/core_dprg";

class DPRGRequesterCMP extends Component {
    constructor(props) {
        super(props);

        this.state = {
            n: 10,
            l: 1000,
            k: 1,
            d: 16,
            requestID: "",
            downloadLink: "",
            uploadInfo: ""

        }

        this.callbackRequestID = this.callbackRequestID.bind(this);
        this.callRequestID = this.callRequestID.bind(this);
        this.callbackRequestCheck = this.callbackRequestCheck.bind(this);
        this.callRequestCheck = this.callRequestCheck.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.PadWithZeros = this.PadWithZeros.bind(this);
    }

    callbackRequestID(data) {
        this.setState({ requestID: data.requestID });
    }

    callRequestID() {
        CoreDPRG.getRequestID(this.callbackRequestID, this.state.n, this.state.l, this.state.k, this.state.d);
    }

    PadWithZeros(value, length) {
        return value.toString().padEnd(length,'0');//.padStart(length, '0');
      }

    callbackRequestCheck(data) {
        if (data.data !== undefined && Array.isArray(data.data)) {
            //console.log(data);
            // Convert the JSON object to a JSON string
            //const jsonData = JSON.parse(data, null, 2);

            // Create a Blob with the JSON string as data
            const dataArray = data.data;
            const separator = '\n'; // Adjust the separator as needed
           
            // Left-pad each element in the data array and add to the result array
            const paddedData = dataArray.map((value) => (this.PadWithZeros(value, 18)));
            const arrayAsString = paddedData.join(separator);

            const blob = new Blob([arrayAsString], { type: 'text/plain' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            //this.state.setState({ downloadLink: url });

            const link = document.createElement('a');
            link.href = url;
            link.download = 'sequence.txt'; // File name for the download
            link.click();

            // Clean up the temporary URL and link
            URL.revokeObjectURL(url);

        } else {

            this.setState({ uploadInfo: data.message });
        }

    }

    callRequestCheck() {
        this.setState({ uploadInfo: '' });
        CoreDPRG.getRequestCheck(this.callbackRequestCheck, this.state.requestID);
    }
    handleChange(e) {
        console.log(e.target.name);
        this.setState({ [e.target.name]: e.target.value });
    }



    render() {

        return (
            <div className="DPRGRequesterCMP">

                <form id="requestID">
                    <div className="set_holder">
                        <label htmlFor="n" className="label">n:</label>
                        <input type="text" id="n" name="n" required className="input" value={this.state.n} onChange={this.handleChange} />
                    </div>

                    <div className="set_holder">
                        <label htmlFor="l" className="label" >l:</label>
                        <input type="text" id="l" name="l" required className="input" value={this.state.l} onChange={this.handleChange} />
                    </div>
                    <div className="set_holder">
                        <label htmlFor="k" className="label">k:</label>
                        <input type="text" id="k" name="k" value={this.state.k} disabled className="input" onChange={this.handleChange} />
                    </div>

                    <div className="set_holder">
                        <label htmlFor="d" className="label">d:</label>
                        <input type="text" id="d" name="d" value={this.state.d} disabled className="input" onChange={this.handleChange} />
                    </div>
                    <div className="submit" onClick={this.callRequestID}>Request Custom Sequence</div>
                </form>
                <label htmlFor="requestID" className="label2">Request ID:</label>
                <input type="text" id="requestID" name="requestID" className="request-label" value={this.state.requestID} onChange={this.handleChange}/>

                <br />
                <div className="submit" onClick={this.callRequestCheck}>Check Request & Download</div>
                <br />
                <div >* Responses are processed by our staff so please be patient. Process can take from 1h to 24h</div>
                <div >{this.state.uploadInfo}</div>
            </div>
        );
    }
}

export default DPRGRequesterCMP;