import React, { Component } from 'reactn';
import './ButtonToTop.scss'

class ButtonToTop extends Component {
    constructor(props) {
        super(props)

        this.state = {

            "show": false
        }

        this.scrollFunction = this.scrollFunction.bind(this);
        this.topFunction = this.topFunction.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollFunction);
        this.scrollFunction();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFunction)
    }


    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        //document.body.scrollTop = 0;
        //document.documentElement.scrollTop = 0;

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (window.location.hash) {
            window.location.hash = '';
        }

    }

    goToTop = () => {
       
    };

    scrollFunction() {
        
        //console.log(window.scrollY);
        if (window.scrollY > 200) {
            // Hide the menu
            this.setState({ "show": true });
            //console.log(window.scrollY);
          } else {
            // Show the menu
            this.setState({ "show": false });
          }

          /*
        
        if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
            this.setState({ "show": true });
        } else {
            this.setState({ "show": false });
        }*/
    }

    render() {

        let button = this.state.show ? 'toTOP show' : 'toTOP';
//{button}
        return (
            <div className="ButtonToTop">
                
                <div onClick={this.topFunction} className={button} title="top"><div className="arrow"> &uarr;</div></div>
            </div>
        )
    }

}

export default ButtonToTop;