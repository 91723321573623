class PageLinks {

    static DPRG = "/DPRG/";
    static ABIT = "/abit/";
    static SMARTCONTRACTS = "//SMART-CONTRACT//"
    static SWIFTLOCK = "/swiftlock/"
    static KNAPSACK = "/knapsack/";

    static HEALTHYJOHNNY = "/healthyjohnny/";
    
}


export default PageLinks;