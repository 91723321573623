import './App.scss';

//import React, { setGlobal, getGlobal } from 'reactn';
//import CoreCookies from './functional/core_cookies'

import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { HashLink as Link } from 'react-router-hash-link';

import HomePage from './components/pages/home_page/HomePage';
import DPRGPage from './components/pages/dprg/DPRGPage';
import KnapsackPage from './components/pages/knapsack/KnapsackPage';

import MenuCmp from './components/macro/menu/MenuCmp';
import FooterCmp from './components/macro/footer/FooterCmp';
import ButtonToTop from './components/elements/button_to_top/ButtonToTop';
import SmartContractPage from './components/pages/smart-contracts/SmartContractPage';
import ABitPage from './components/pages/abit/ABitPage';
import ENTPage from './components/pages/ent/ENTPage';
import SwiftLockPage from './components/pages/swiftlock/SwiftLockPage';
import HealthyJohnnyPage from './components/pages/healthy_johnny/HealthyJohnnyPage';



// Set an initial global state directly:
// setGlobal({
//   lang: undefined
// });


// var $curr = 'pl';
// var $alt = 'en';
// var $isEn = false;

// let $lang = CoreCookies.getLanguage();

// if ($lang === 'en') {
//   $isEn = true;
// }

// setGlobal({
//   langCode: $lang,
//   lang: $curr,
//   isEn: $isEn
// });


function App() {

  // function hashLinkScroll() {
  //   const { hash } = window.location;
  //   if (hash !== '') {
  //     // Push onto callback queue so it runs after the DOM is updated,
  //     // this is required when navigating from a different page so that
  //     // the element is rendered on the page before trying to getElementById.
  //     setTimeout(() => {
  //       const id = hash.replace('#', '');
  //       const element = document.getElementById(id);
  //       if (element) element.scrollIntoView();
  //     }, 0);
  //   }
  // }

  return (

    <BrowserRouter>
      <div className="App">
        <MenuCmp></MenuCmp>
        <ButtonToTop />

        <div className="content">

          <Routes >
            <Route exact path="/" element={<HomePage />} />
            <Route path="DPRG" element={<DPRGPage />} />
            <Route path="/DPRG" element={<DPRGPage />} />
            <Route path="/DPRG/" element={<DPRGPage />} />
            <Route path="dprg" element={<DPRGPage />} />
            <Route path="dprg/" element={<DPRGPage />} />
            <Route path="/dprg/" element={<DPRGPage />} />
            <Route path="/aBit/" element={<ABitPage />} />
            <Route path="/abit/" element={<ABitPage />} />
            <Route path="/ent/" element={<ENTPage />} />
            <Route path="/knapsack/" element={<KnapsackPage />} />
            <Route path="/swiftlock/" element={<SwiftLockPage />} />
            <Route path="/healthyjohnny/" element={<HealthyJohnnyPage />} />
           <Route path="/SMART-CONTRACT/" element={<SmartContractPage />} />
            

            <Route index element={<HomePage />} />
          </Routes>


          <FooterCmp />
        </div>

      </div>
    </BrowserRouter>
  );
}
//
export default App;

/*
  
            */

/*
    <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>

*/