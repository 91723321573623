import { Component } from "reactn";
import './ValuesCmp.scss';

import ValueList from '../value_list/ValueListCmp';

class ValuesCmp extends Component {
    state = {}

    render() {

        return (
            <div className="ValuesCmp">
                <div className="title">
                    <h1>What We Value</h1>
                </div>
                <div className="content">
                    <ValueList></ValueList>
                </div>

            </div>
        );
    }

}

export default ValuesCmp;