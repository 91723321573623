import { Component } from "reactn";
import "./NewsletterCMP.scss";
// import PropTypes from 'prop-types';
import axios from "axios";

class NewsletterCMP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            send: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    state = {}

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        axios.post('https://api.smart-fox-innovation.com/api/api-newsletter.php', {
            email: this.state.email,
        })
            .then((response) => {
                this.setState({email:''});

                this.setState({send: "Thank you!", status_class: 'ok'});
            })
            .catch((error) => {
                this.setState({send: "Error while sending the message, try again?", status_class: 'nok'});
                console.error(error);
            });
    }         
    
    render() {
        return (

            <div className="NewsletterCMP">
                <form onSubmit={this.handleSubmit}>
                    <div className="container">
                        <h2>Subscribe to our Newsletter</h2>
                        <p>Stay informed and sign in for cutting edge technology from us</p>
                    </div>

                    <div className="container">
                        <input type="email" placeholder="Email address"  value={this.state.email} onChange={this.handleChange} name="email" required />
                        <input type="submit" value="Subscribe" />
                    </div>
                    <div>{this.state.send}</div>
                </form>

            </div>
        );
    }
}

export default NewsletterCMP;

