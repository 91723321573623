import { Component } from "reactn";
import "./MovieCMP.scss";
import PropTypes from 'prop-types';


class MovieCMP extends Component {
  /*  constructor(props) {
        super(props);
    }*/
    
 
    state = {}
    render() {
        return (

            <div className="MovieCMP">
                <video className="video" controls>
                    <source src={"/videos/" + this.props.videoName} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            </div>
        );
    }
}

MovieCMP.propTypes = {
    videoName: PropTypes.string.isRequired
};


export default MovieCMP;

