
import React, { Component } from 'reactn'
import './ValueListCmp.scss';

class ValueList extends Component {
    constructor(props) {
        super(props);

        this.mapGallery = this.mapGallery.bind(this);

    }
    state = {
        valuesList: [
            {
                img: "innovation_150x150.webp",
                name: "innovation"
            },
            {
                img: "passion_150x150.webp",
                name: "passion"
            },
            {
                img: "next_great_thing_150x150.webp",
                name: "next_great_thing"
            },
            {
                img: "agility_150x150.webp",
                name: "agility"
            },
            {
                img: "pragmatism_150x150.webp",
                name: "pragmatism"
            }
        ]

    }

    componentDidMount() {
        //TOP 10 PICTURES

        //MORE BUTTON

    }

    render() {

        let images1 = this.state.valuesList.map(this.mapGallery);


        return (
            <div className='ValueListCmp' loading='lazy'>
                <ul className="gallery zoom">
                    {images1}
                </ul>
            </div>
        );
    }


    mapGallery($obj, $index) {

        let $name = $obj.name;//.substr(0, $obj.name.lastIndexOf("."));
        $name = $name.replaceAll("_", " ");

        return <li className="image" key={$index}>
            <img src={"/images/core/values/" + $obj.img} alt="value" className='img' />
            <div className='name'> {$name} </div>
        </li>
    }


}



export default ValueList;

