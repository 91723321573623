import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './SmartContractPage.scss'
import FeedbackCmp from "../../elements/feedback/FeedbackCmp";
import MovieCMP from "../../elements/movie/MovieCMP";

class SmartContractPage extends Component {
  
    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        //let sample_list = this.state.dprg_sample.map(addExampleFile);


        return (
            <div className="SmartContractPage">
                <div className="title">Smart Contracts  <h5>Automated, secure, and transparent agreements</h5></div>

                <div className="info-box">
                    <img src="/images/products/smart-contract-logo-250x250.webp" alt="DPRG" className="img" />
                    <div className="description">
                        Smart Contracts revolutionize the way we manage agreements by automating, securing, and making them transparent. Built on blockchain technology, these digital contracts execute predefined actions without the need for intermediaries. They're versatile, adaptable across various sectors like finance, healthcare, and public administration. Experience hassle-free, transparent dealings with Smart Contracts.

                    </div>
                </div>


                <MovieCMP videoName = "smart-contracts-public-eye.mp4"></MovieCMP>

                <FeedbackCmp />

                </div>


        )};



}

export default SmartContractPage;
