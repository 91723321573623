import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './HealthyJohnnyPage.scss'

class HealthyJohnnyPage extends Component {

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        //let sample_list = this.state.dprg_sample.map(addExampleFile);


        return (
            <div className="HealthyJohnnyPage">
                <div className="title"> <h5> Project Healthy Johnny (Zdrowy Jaś) </h5></div>

                <div className="info-box">
                    <img src="/images/core/values/projekt_zdrowy_jas.jpg" alt="Jas" className="img" />
                    <div className="description">
                    <br/>This year, 2nd grade students from Econom in Mińsk Mazowiecki carried out a project as part of the national olympiad "Exempt from Theory" (Zwolnieni z Teorii) entitled: “Healthy Johnny.” (Zdrowy Jaś) 
                    <br/><br/>They conducted classes during which they expanded students' knowledge about a healthy lifestyle. The beneficiaries of these classes were preschoolers and primary school students. The project conducted over 50 classes in which over 1,300 children took part!!!
                    <br/><br/>Hearty congratulations to everyone involved in the project supporting a healthy lifestyle among young people!
                    <br/><br/>Your work is invaluable for educating young people about healthy eating, physical activity and good habits. Thanks to you, young people learn how to avoid negative behavior, cope with stress and enjoy healthy sleep. Your commitment and dedication contribute to building a stronger, healthier future for all of us. You are an inspiration to us and you show how much change can be introduced through passion and hard work. 
                    <br/><br/><br/>Thank you!

                 </div>
                </div>

          
            </div>


        )
    };



}

export default HealthyJohnnyPage;
