import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './ENTPage.scss'
import FeedbackCmp from "../../elements/feedback/FeedbackCmp";
import MovieCMP from "../../elements/movie/MovieCMP";

class ENTPage extends Component {

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        //let sample_list = this.state.dprg_sample.map(addExampleFile);


        return (
            <div className="ENTPage">
                <div className="title"> ENT(GEN-VR)<h5>Encoded Number Theory</h5></div>

                <div className="info-box">
                    <img src="/images/products/ent-250x250.webp" alt="ENT" className="img" />
                    <div className="description">
                        <b> Public Brief on ENT</b> <br />
                        The ENT System is a groundbreaking approach to data security, leveraging unique mathematical principles to offer unparalleled levels of encryption. While it was initially envisioned as a solution for data compression, its real strength lies in its ability to secure information.
                        <br /> <br />
                        Key Features:
                        <br />
                        <br />
                        <b>Unmatched Encryption:</b> Utilizes a unique mathematical foundation to create encryption that is extremely difficult to break.
                        <br />
                        <b>Adaptive Security:</b> Tailors encryption methods to the specific nature of the data, providing optimized security.
                        <br />
                        <b>Non-reversibility:</b> The unique encoding scheme makes it virtually impossible to reverse-engineer the original data without access to the full set of encryption parameters.
                        <br />
                        <b>Speed and Efficiency:</b> Designed for quick data encryption and decryption, making it ideal for applications requiring real-time secure data access.

                        <b>Potential Use-Cases</b>
                        <ul>
                        <li>Financial Transactions</li>
                        <li>Medical Records Storage</li>
                        <li>National Security Applications</li>
                        <li>Secure Data Transmission</li>
                        </ul>
                        <b>Benefits</b>
                        <br />
                        <b>Robust Security:</b> Virtually unbreakable encryption unless all encryption parameters are known. <br />
                        <b>Fast Access:</b> Efficient algorithms allow for quick data retrieval, encrypted or decrypted.
                    </div>
                </div>

                <MovieCMP videoName="ent-gen-vr-public.mp4"></MovieCMP>

                <FeedbackCmp />

            </div>


        )
    };



}

export default ENTPage;
