//import { setGlobal } from 'reactn'
import axios from 'axios';
import CoreApi from './core_api';

export default class CoreKNAPSACK {

    static getRequestID(callback, capacity, maxItemsPerBox, maxVolumePerBox, W, V, S, I) {
        if (capacity !== undefined && maxItemsPerBox !== undefined && maxVolumePerBox !== undefined && W !== undefined && V !== undefined && S !== undefined && I !== undefined) {
            let json = JSON.stringify({ "capacity": capacity, "maxItemsPerBox": maxItemsPerBox, "maxVolumePerBox": maxVolumePerBox, "W": W, "V": V, "S": S, "I": I });

            let config = { headers: { Authorization: CoreApi.API_KEY } };

            axios.post(CoreApi.getRequestIDKnapsack, json, config)
                .then(res => {
                    res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrieve any data")
                }).catch(function (err) {
                    console.log(err.message);
                })
        }
    }

    static getRequestCheck(callback, requestID) {
        if (requestID !== undefined) {
            let json = JSON.stringify({ "requestID": requestID });

            let config = { headers: { Authorization: CoreApi.API_KEY } };

            axios.post(CoreApi.getRequestCheckKnapsack, json, config)
                .then(res => {
                    res !== undefined && res.status === 200 ? callback(res.data) : console.log("did not retrieve any data")
                }).catch(function (err) {
                    console.log(err.message);
                })
        } else {
            console.log("Error requestID is undefined");
        }


    }



}