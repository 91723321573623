import { Component } from "reactn";
//import { Link } from 'react-router-dom';
import './SwiftLockPage.scss'
import FeedbackCmp from "../../elements/feedback/FeedbackCmp";
import MovieCMP from "../../elements/movie/MovieCMP";
import DownloadCMP from "../../elements/download/DownloadCMP";

class SwiftLockPage extends Component {

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {

        //let sample_list = this.state.dprg_sample.map(addExampleFile);


        return (
            <div className="SwiftLockPage">
                <div className="title">SwiftLock<h5>Security for Your Digital World</h5></div>

                <div className="info-box">
                    <img src="/images/products/swiftlock-250x250.webp" alt="SWL" className="img" />
                    <div className="description">
                        Swift Lock is a cutting-edge security solution designed to safeguard your digital assets and privacy. Leveraging state-of-the-art encryption and authentication methods, Swift Lock ensures robust protection against unauthorized access. With a focus on user-friendly interface and seamless integration, Swift Lock empowers you to take control of your online security, providing peace of mind in an ever-evolving digital landscape.  </div>
                </div>

                <MovieCMP videoName="swiftlock.mp4"></MovieCMP>

                <br />Swift Lock has CLI provided for efficiency and user friendly usage
                <br />Ready and working implementation is ready today to prove Swift Lock abilities!
                <br />
                <div className="description-code">
                    -e -in HelloWorld.txt -out HelloWorld.swl
                </div>
                <div className="description-code">
                    -d -in HelloWorld.swl -out HelloWorld.dec -key S1:536377321015438:S2:113094060929372701:S3:32463479580350230:SN:1166763264:OF:903946496:ST:40:BM:1
                </div>
               
                <div className="text">
                    A simple sample with key included to show case SwiftLock potential
                </div>
                <DownloadCMP filePath="swiftlock_sample\" fileName="swiftlock_sample.zip" Name="Download Example (Hello World!)"></DownloadCMP>

                <div className="description-code">
                    Swift Lock Key Space is more than 2^254 * 10^3
                </div>

                <div className="description-code">
                    Swift Lock all or part of the key can be pre-shared.
                </div>

                <FeedbackCmp />

            </div>


        )
    };



}

export default SwiftLockPage;
