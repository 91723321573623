import { Component } from "reactn";
import './AboutCmp.scss';

class AboutCmp extends Component {

    state = {}

    render() {

        return (
            <div className="AboutCmp">
                <div className="title">
                    <h1>About Us</h1>
                </div>

                <div className="content">
                    <div className="info-box">

                        <img src="/images/core/custom/fox-man-250x250.webp" alt="about us"></img>

                        <div className="text2">
                            <p>Fox Tech Innovations is a leading technology company specializing in cutting-edge solutions in the field of Information Technology, Cybernetics, AI, Physics and Mathematics in practical use.</p>

                            <p>Since our inception in 2020, we have been committed to delivering excellence and innovation in all our products and services.
                                Our drive is to create technology solutions that not only meet the current needs of our clients but also anticipate future trends.
                                Our team of highly skilled professionals is dedicated to delivering quality and value in every project we undertake.</p>

                            <p>From software development and IT consulting to advanced AI and machine learning solutions,
                                we provide a wide range of services to help businesses leverage technology for growth and success.
                                We are passionate about technology and strive to stay ahead of the curve in the ever-evolving tech landscape.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AboutCmp;