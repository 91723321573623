
export default class CoreApi {

    static baseUrlMock = "http://127.0.0.1:3100";
    static baseUrl = " https://api.smart-fox-innovation.com/api/";
    static sessionID = 'SID';
    static API_KEY ="5171637c6dd141cc1b874e98272dc0ebb901189e745ac151040670d7b014edd5"
    
    //DPRG GET
    static getRequestID = `${CoreApi.baseUrl}dprg/api-dprg-request.php`;
    static getRequestCheck = `${CoreApi.baseUrl}dprg/api-dprg-check.php`;

    //KNAPSACK GET
    static getRequestIDKnapsack = `${CoreApi.baseUrl}knapsack/api-knapsack-request.php`;
    static getRequestCheckKnapsack = `${CoreApi.baseUrl}knapsack/api-knapsack-check.php`;

    
}