import './FooterCmp.scss'
import React, { Component } from 'reactn'

class FooterCmp extends Component {
    state = {}
    render() {
        return (

            <footer className='FooterCmp'>
                <p>&copy; {new Date().getFullYear()} Smart Fox Innovations. All rights reserved.</p>
            </footer>


        );
    }
}

export default FooterCmp;
